import React, { useState, useCallback } from 'react';
import { datadogRum  } from '@datadog/browser-rum';
import Stack from '@mui/material/Stack';
import { useAuth0 } from '@auth0/auth0-react';
import Box from '@mui/material/Box';
import { TextField, Button } from '@mui/material';
import APIKeySecretDialog from './APIKeySecretDialog';

const styles = {
  container: {
    borderTop: '1px solid gray'
  },
  inputContainer: {
    justifyContent: 'space-around', 
    alignItems: "center", 
    padding: "10px 20px"
  },
  textField: {
    paddingRight: "20px"
  }
};

function AddAPIKeyListItem(props) {
  const {
    organization,
    setLoadAPIKeys,
    canAddKey
  } = props;

    const { getAccessTokenSilently } = useAuth0();

  const [addingKey, setAddingKey] = useState(false);
  const [ newAPIKeyName, setNewAPIKeyName ] = useState("");
  const [ newAPIKeyClientSecret, setNewAPIKeyClientSecret ] = useState(null);
  const [ newAPIKeyClientID, setNewAPIKeyClientID ] = useState(null)

  const createNewAPIKey = useCallback(() => {
    setAddingKey(true);
    getAccessTokenSilently().then(accessToken => {
      const headers = new Headers({
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      });

      return fetch(
        `${process.env.REACT_APP_KEYNOME_API_URL_BASE}/v1/organizations/${organization.id}/api_keys`,
        {
          method: 'POST',
          headers,
          body: JSON.stringify(
            {
              data: {
                type: "api_key",
                attributes: {
                  "name": newAPIKeyName,
                  "description": newAPIKeyName
                }
              }
            }
          )
        },
      )
        .then((res) => {
          if (!res.ok) {
            throw new Error(`Request to ${res.url} failed with ${res.status} (${res.statusText})`)
          }

          return res.json()
        })
        .then((response) => {
          setNewAPIKeyName("")
          setNewAPIKeyClientSecret(response["data"]["attributes"]["client_secret"])
          setNewAPIKeyClientID(response["data"]["attributes"]["auth0_client_id"])
          setLoadAPIKeys(true)
          setAddingKey(false)
        })
        .catch(datadogRum.addError)
    })
  }, [newAPIKeyName, organization, setLoadAPIKeys, getAccessTokenSilently]);

  const nameIsValid = new RegExp(/^[a-zA-Z0-9 -_]{1,75}$/).test(newAPIKeyName)

  return (
    <Box sx={styles.container}>
      <Stack direction="row" spacing={2} sx={styles.inputContainer}>
        <TextField
          disabled={addingKey || !canAddKey}
          id="name-textfield"
          label="New Client Name"
          placeholder="Brief name to identify Keynome Manager Client"
          value={newAPIKeyName}
          variant="standard"
          fullWidth
          maxRows={1}
          onChange={(event) => setNewAPIKeyName(event.target.value)}
          sx={styles.textField}
        />
        <Button 
          disabled={!nameIsValid || addingKey || !canAddKey} 
          variant="contained" 
          size="small" 
          color="success" 
          onClick={createNewAPIKey}
        >
          Add
        </Button>
      </Stack>
      <APIKeySecretDialog
        isOpen={!!newAPIKeyClientSecret} 
        clientID={newAPIKeyClientID} 
        APIKeySecret={newAPIKeyClientSecret} 
        closeAPIKeySecretDialog={
          ()=> {
            setNewAPIKeyClientSecret(null);
            setNewAPIKeyClientID(null);
          }
        }
      />
    </Box>
  );
}

export default AddAPIKeyListItem;
