import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';

import DataStorageRegion from './DataStorageRegion';

const styles = {
  container: {
    p: 2,
  },
  orgLabel: {
    color: 'text.secondary',
    fontSize: 'small',
  },
  metaInfo: {
    mb: 2,
  },
  divider: {
    my: 2,
  },
  progressContainer: {
    display: 'flex',
    alignItems: 'center',
    mt: 1,
  },
  progressBar: {
    width: '100%',
    mr: 1,
  },
  progressLabel: {
    minWidth: 35,
  }
};

function OrganizationDetails({ organization, organizationAttributesWithUsage }) {
  const usagePercentage = organizationAttributesWithUsage?.uploaded_gigabases_current_month_limit
    ? Math.min(
        Math.round(
          (organizationAttributesWithUsage.uploaded_gigabases_current_month / 
           organizationAttributesWithUsage.uploaded_gigabases_current_month_limit) * 100
        ),
        100
      )
    : 0;

  return (
    <Box sx={styles.container}>
      <Typography sx={styles.metaInfo}>
        <Box component="span" fontWeight="bold">{organization?.attributes.name}{' '}</Box>{' '}
        <Typography component="span" sx={styles.orgLabel}>
          ({organization?.attributes.label || '-'})
        </Typography>
        </Typography>
      <Stack direction="row" spacing={3} sx={styles.metaInfo}>
        <Typography>
          <Box component="span" fontWeight="bold">Created:</Box>{' '}
          {organization ? new Date(organization.attributes.created_at).toLocaleDateString() : '-'}
        </Typography>
        <Typography>
          <Box component="span" fontWeight="bold">Last updated:</Box>{' '}
          {organization ? new Date(organization.attributes.updated_at).toLocaleDateString() : '-'}
        </Typography>
      </Stack>

      <Divider sx={styles.divider} />

      <Stack direction="row" spacing={3} sx={styles.metaInfo}>
        <Typography sx={styles.metaInfo}>
          <Box component="span" fontWeight="bold">Plan:</Box>{' '}
          {organization?.attributes.plan_label || 'Unlimited'}
        </Typography>
        <DataStorageRegion region={(organization?.attributes.label === 'gstt' || organization?.attributes.label === 'dzd-eu') ? 'eu' : 'us'} />
      </Stack>

      <Box sx={styles.metaInfo}>
        <Typography>
          <Box component="span" fontWeight="bold">Users:</Box>{' '}
          {organizationAttributesWithUsage?.users_ct || '-'} of{' '}
          {organizationAttributesWithUsage?.users_ct_limit || '∞'} user limit
        </Typography>
      </Box>
        
      <Box sx={styles.metaInfo}>
        <Typography>
          <Box component="span" fontWeight="bold">Uploaded sequencing data:</Box>{' '}
          {organizationAttributesWithUsage?.uploaded_gigabases_current_month.toLocaleString() || '-'} GB of{' '}
          {organizationAttributesWithUsage?.uploaded_gigabases_current_month_limit || '∞'} GB per month limit
        </Typography>
      </Box>

      {organizationAttributesWithUsage?.uploaded_gigabases_current_month_limit && (
        <Box sx={styles.progressContainer}>
          <Box sx={styles.progressBar}>
            <LinearProgress 
              variant="determinate" 
              value={usagePercentage} 
            />
          </Box>
          <Typography variant="body2" color="text.secondary" sx={styles.progressLabel}>
            {`${usagePercentage}%`}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default OrganizationDetails;