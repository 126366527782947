import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

const styles = {
  flag: {
    marginBottom: -3,
    height: '20px',
  },
  infoIcon: {
    padding: '2px',
    marginLeft: '4px',
    color: 'rgba(0, 0, 0, 0.4)',
    marginTop: '-10px',
  },
  dialogFlag: {
    height: '20px',
    marginRight: '8px',
    verticalAlign: 'middle',
  },
  dialogTitle: {
    backgroundColor: '#eee',
    display: 'flex',
    alignItems: 'center',
  },
  dialogContent: {
    marginTop: '20px',
    padding: '20px 40px',
  },
  dialogActions: {
    backgroundColor: '#eee',
  },
};

const getFlagDetails = (region) => {
    switch (region?.toLowerCase()) {
        case 'eu':
            return {
                src: 'eu_flag.png',
                alt: 'EU Flag',
                label: 'Europe (Central)',
                description: [
                    'All sequencing data and associated metadata will be stored in Europe',
                    'All sequencing data processing will be performed in Europe',
                    'Note: Performance monitoring and debugging data may be processed in other regions to support service optimization'
                ]
            };
        default:
            return {
                src: 'us_flag.png',
                alt: 'US Flag',
                label: 'US (Central)',
                description: [
                    'All sequencing data and associated metadata will be stored in the United States',
                    'All sequencing data processing will be performed in the United States',
                    'Note: Performance monitoring and debugging data may be processed in other regions to support service optimization'
                ]
            };
    }
};

function DataStorageRegion({ region }) {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const { src, alt, label, description } = getFlagDetails(region);
  
    return (
      <>
        <Typography>
          <Box component="span" fontWeight="bold">Data storage:</Box>{' '}
          <img src={src} alt={alt} style={styles.flag} /> {label}
          <IconButton
            size="small"
            onClick={() => setIsDialogOpen(true)}
            sx={styles.infoIcon}
          >
            <InfoIcon fontSize="small" />
          </IconButton>
        </Typography>
  
        <Dialog
          open={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle sx={styles.dialogTitle}>
            <img src={src} alt={alt} style={styles.dialogFlag} />
            {label} Data Storage
          </DialogTitle>
          <DialogContent sx={styles.dialogContent}>
            {description.map((text, index) => (
              <Typography key={index} paragraph>
                {text}
              </Typography>
            ))}
          </DialogContent>
          <DialogActions sx={styles.dialogActions}>
            <Button
              variant="contained"
              color="primary"
              sx={{ marginRight: '20px' }}
              onClick={() => setIsDialogOpen(false)}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

export default DataStorageRegion;